import authConfig from "./AuthConfig";

export default class AuthService {

  static login(user) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = authConfig.login;
        let response = await authConfig.Connector.post(endpoint, {
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log(" >>>>>>>>>>error ", error);
        reject(error);
      }
    });
  }

  static phoneLastNumbers(wToken) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = authConfig.phoneLastNumbers;
        let response = await authConfig.Connector.post(endpoint, {
          body: JSON.stringify({wToken:wToken}),
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log(" >>>>>>>>>>error ", error);
        reject(error);
      }
    });
  }

  static createAccount(user) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = authConfig.preRegister;
        let response = await authConfig.Connector.post(endpoint, {
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log(" >>>>>>>>>>error ", error);
        reject(error);
      }
    });
  }
}
