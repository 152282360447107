import Colors from "./Colors"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography:{
    body1:{
    },
    h1:{
      color:Colors.mediumBlue,
      fontSize:35,
      '@media (max-width:600px)':{
        fontSize:25
      },
      textAlign:"center",
      fontFamily:"Nunito",
      fontWeight:700
    },
    h2:{
      color:Colors.primary_color,
      fontSize:28,
      '@media (max-width:600px)':{
        fontSize:18,
        marginTop: 15,
      },
      textAlign:"center",
      fontFamily:"Nunito",
      fontWeight:700
    },
    h5:{ //Terminos y condiciones
      color:Colors.grey124,
      fontSize: 15,
      textAlign:"left",
      marginTop: 20,
      marginBottom: 20,
      lineHeight:0.2,
      fontFamily:"Nunito",
      '@media (max-width:600px)':{
        fontSize:14,
        lineHeight:1,
      },
      textAlign:"left"
    },
    h6:{ //Footer
      color:Colors.grey152,
      fontSize: 13,
      textAlign:"center",
      marginTop: 30,
      display: "flex",
      alignItems:"center",
      justifyContent: "center"
    },
    body2:{ //StepLabel
      color:Colors.grey124,
      fontSize:18,
      '@media (max-width:600px)':{
        fontSize:18
      },
      fontFamily:"Nunito",
      fontWeight:700
    },
    button:{
      background: `linear-gradient(${Colors.brightCyan},${Colors.gradientBlue})`,
      textTransform:"capitalize"
    }
  },
  overrides:{
    MuiButton:{
      contained:{
        color:"white"
      },
      text:{
        background: "transparent",
        color:Colors.mediumBlue,
        fontSize:18,
        '@media (max-width:600px)':{
          fontSize:14,
          lineHeight:1
        },
        textTransform:"none",
        textAlign:"center",
        padding:0,
      },
      label:{
        fontFamily:"Nunito",
        fontWeight:700
      }
    },
    MuiStepConnector:{
      lineVertical:{
        borderColor:Colors.lightBlue,
        borderLeftWidth:4,
        marginLeft:15
      },
      vertical:{
        padding:0
      }
    },
    MuiFormControl:{
      root:{
        minWidth:100,
        '@media (max-width:600px)':{
          minWidth:100
        }
      }
    }
  },
  props:{

  }
})

const Theme = ({children})=>{
  return <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider> 
}

export default Theme