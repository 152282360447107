import Noty from "noty";
import moment from "moment";
import ReactDOM from "react-dom";

// Para mas info consultar en https://ned.im/noty/#/
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const WARNING = "WARNING";
export const ALERT = "ALERT";
export const INFO = "INFO";
export const BUTTON = "BUTTON";
export const COMPLEX = "COMPLEX";
export const COMPLEX_TIME = "COMPLEX_TIME";
export const SENDING = "SENDING";

export const DESKTOP="DESKTOP"
export const MOBILE="MOBILE"

export default class Util {
  /* Parametros:
      msg_little: String con el mensaje de tamaño menor.
      type: String, puede ser: success, info, alert, error).
      categ: String, puede ser: Informacion, Mensaje, etc.
      msg_big: String, mensaje de tamaño mayor.
      image: imagen que se mostrata como icono.
  */
  static notyComplex(msg_little, type, categ, msg_big, image, time) {
    new Noty({
      text: msg_little,
      type: type,
      category: categ,
      timeout: 5000,
      category_link: "",
      date: Date.now(),
      from: msg_big,
      avatar: image,
      closeWith: ["button"],
      progressBar: true,
      callbacks: {
        onTemplate: function () {
          if (this.options.type === type) {
            this.barDom.innerHTML =
              '<div class="my-custom-template noty_body">';
            this.barDom.innerHTML +=
              "<div class='noty_custom_container'>" +
              "<div>" +
              '<div class="noty-header"' +
              "</span></div>" +
              "<div>" +
              '<p class="noty-reply">' +
              this.options.text +
              "</p>" +
              "</div>" +
              "</div>" +
              "<div class='noty_custom_icon'>" +
              '<img src="' +
              this.options.avatar +
              '">' +
              "</div>";
            this.barDom.innerHTML += "<div>";
          }
        },
      },
    }).show();
  }

  static notySuccess(msg) {
    new Noty({
      theme: "relax",
      type: "success",
      layout: "topRight",
      text: msg,
      timeout: 2000,
      progressBar: true,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notyError(msg) {
    new Noty({
      theme: "relax",
      type: "error",
      layout: "topRight",
      text: msg,
      progressBar: false,
      timeout: 5000,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notyWarning(msg) {
    new Noty({
      theme: "relax",
      type: "warning",
      layout: "bottomRight",
      text: msg,
      timeout: 3000,
      progressBar: true,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notyAlert(msg) {
    new Noty({
      theme: "relax",
      type: "alert",
      layout: "centerLeft",
      text: msg,
      timeout: 3000,
      progressBar: false,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notySending(msg) {
    new Noty({
      theme: "relax",
      type: "info",
      layout: "topRight",
      text: msg,
      timeout: 5000,
      progressBar: false,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notyInfo(msg) {
    new Noty({
      theme: "relax",
      type: "info",
      layout: "bottomLeft",
      text: msg,
      progressBar: false,
      closeWith: ["button"],
      animation: {
        open: "noty_effects_open",
        close: "noty_effects_close",
      },
      id: false,
      force: false,
      killer: false,
      queue: "global",
      container: false,
      buttons: [],
      sounds: {
        sources: [],
        volume: 1,
        conditions: [],
      },
      titleCount: {
        conditions: [],
      },
      modal: false,
    }).show();
  }

  static notyButton(msg) {
    var n = new Noty({
      text: msg,
      layout: "center",
      buttons: [
        Noty.button(
          "YES",
          "btn btn-success",
          function () {
            // console.log("button 1 clicked");
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("NO", "btn btn-error", function () {
          // console.log("button 2 clicked");
          n.close();
        }),
      ],
    }).show();
  }

  static showNoty(result, message_little, is_type, category, message_big, ima) {
    if (result === ERROR) {
      this.notyError(message_little);
    } else if (result === WARNING) {
      this.notyWarning(message_little);
    } else if (result === SUCCESS) {
      this.notySuccess(message_little);
    } else if (result === ALERT) {
      this.notyAlert(message_little);
    } else if (result === INFO) {
      this.notyInfo(message_little);
    } else if (result === BUTTON) {
      this.notyButton(message_little);
    } else if (result === COMPLEX) {
      this.notyComplex(message_little, is_type, category, message_big, ima);
    } else if (result === COMPLEX_TIME) {
      this.notyComplex(
        message_little,
        is_type,
        category,
        message_big,
        ima,
        true
      );
    } else if (result === SENDING) {
      this.notySending(message_little);
    }
  }

  static sortAtoZ(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  static formatDateTimeToISODate(date, time) {
    let returnValue = moment(date);
    let _time = moment(time);
    returnValue.set({
      hour: _time.get("hour"),
      minute: _time.get("minute"),
    });
    return moment(returnValue).format("YYYY-MM-DDTHH:mm");
  }

  static formatDateToShow(date) {
    return date ? moment(date).format("DD/MM/YYYY HH:mm") : null;
  }
  static formatDate(date) {
    return date ? moment(date).format("DD/MM/YYYY") : null;
  }
  static isoDateToJavascriptDate(date) {
    return date ? moment(date, "YYYY-MM-DDTHH:mm").toDate() : null;
  }

  static joinDataAndTime(date, time) {
    let _date = moment(date);
    let _time = moment(time);
    _date.set({
      hour: _time.get("hour"),
      minute: _time.get("minute"),
    });
    return _date;
  }

  static formateDate(date) {
    let day = date.slice(0, 2);
    let month = date.slice(3, 5);
    let year = date.slice(6, 10);

    let completeDate = month + "/" + day + "/" + year;
    let newFormatDate = new Date(completeDate);

    return newFormatDate;
  }

  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const buff = new Buffer(base64, "base64");
    const text = buff.toString("ascii");
    // console.log(JSON.parse(text));
    return JSON.parse(text);
  }
}

export function handleSubmitFail(errors) {
  if (!this.refs || !errors) {
    return;
  }
  const refsKeys = Object.keys(this.refs);
  const keys = Object.keys(errors);
  let key = null;
  let matchfound = false;
  refsKeys.filter((item) => {
    if (keys.indexOf(item) > -1 && !matchfound) {
      key = item;
      matchfound = true;
      return false;
    } else {
      return true;
    }
  });
  this.targetNode = this.refs[key];
  if (this.targetNode) {
    const node = ReactDOM.findDOMNode(this.targetNode);
    if (node.getElementsByTagName("input")[0]) {
      node.getElementsByTagName("input")[0].focus();
      return;
    } else if (
      node.getElementsByTagName("div")[0].getElementsByTagName("textarea")[0]
    ) {
      node
        .getElementsByTagName("div")[0]
        .getElementsByTagName("textarea")[1]
        .focus();
      return;
    }
    const parentNode = ReactDOM.findDOMNode(this);
    const xy = node.getBoundingClientRect();
    this.x = xy.right + window.scrollX;
    this.y = xy.top + window.scrollY - 60;
    (parentNode &&
      parentNode.scrollTo &&
      parentNode.scrollTo(this.x, this.y)) ||
      (parentNode && parentNode.scrollTop && (parentNode.scrollTop = this.y)) ||
      (window && window.scrollTo(this.x, this.y));
  }
}

export const base64toBlob = (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const replaceAll = (str, match, replacement) => {
  return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
}

export const formatNumberWith0 = (value, name) => {
  if (!value && value != 0) {
    return ""
  }
  let aux = value.toString()
  if (aux.endsWith(".")) {
    aux = aux.substring(0, aux.length - 1) + ','
  }
  aux = aux.split('.')
  if (aux[0].length >= 11) {
    aux[0] = aux[0].substring(0, aux[0].length - 1);
  }
  let integ = aux[0].toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return integ
}

export const toNumber = (value, name) => {
  // console.log("TO NUMBER", value, name)
  if (!value) {
    return value
  }
  let num = value.replace(/\./g, '').replace(/,/g, '.')
  if (value.endsWith('.')) {
    return num + ','
  }
  num = parseFloat(num)
  return isNaN(num) ? 0 : num
}


export const includes = (string, search) => {
  let str = string;
  str = str + "";
  return str.includes(search)
}


export const isBetween = (date1, date2, date_to_check) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  date_to_check = new Date(date_to_check);

  if (date_to_check.getTime() <= date2.getTime()
    && date_to_check.getTime() >= date1.getTime()) {
    return true;
  } else {
    return false;
  }
};

export const isEqualDate = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);

  if (date1.getTime() === date2.getTime()) {
    return "red";
  } else {

    if (date1.getTime() <= date2.getTime()) {
      return "#3c8dc7"
    } else {
      return false;
    }
  }
}

export const getScreenSize = ()=>{
  const size = window.innerWidth
  console.log("SIZE",size)
  if(size>=1024){ //TODO Agregar condicional según tamaño de la pantalla
    return DESKTOP
  }
  return MOBILE
}