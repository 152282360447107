import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./config/Store";
import App from "./App";
const persistedState = {}
const store = configureStore(persistedState);

const Root = () => {
    return(
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    )
};

export default Root;
