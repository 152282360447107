import React, { useState } from "react";
import { withRouter } from "react-router-dom";
//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { required, typeInteger } from "../../common/Validations";
import TextFieldRF from "../../components/Textfield";
import SelectFieldRF from "../../components/SelectField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
//Styles
import "./Welcome.css";


import { backgroundLight } from "../../assets/images";
import bgCircleLeft from "../../assets/images/bgCircleLeft.svg"
import bgCircleRight from "../../assets/images/bgCircleRight.svg"
import bgTopLogo from "../../assets/images/bgTopLogo.svg"
import Logos_carrefourTarjetaLogoWhite from "../../assets/images/Logos_carrefourTarjetaLogoWhite.svg"
import Icons_WhatsappIcon from "../../assets/images/Icons_WhatsappIcon.svg"
import Icons_lockIcon from "../../assets/images/Icons_lockIcon.svg"
import Terminos from "../../assets/images/Terminos.svg"

import CheckIcon from '@material-ui/icons/CheckCircle';
import Colors from "../../theme/Colors";
import styles from "../../theme/GlobalStyles";

//ROUTES
import { RESUME_ROUTE } from "../../config/RoutesConsants";

//Notify
import Util, { ERROR } from "../../common/Util";

import { formatNumberWith0, toNumber } from "../../common/Util";

const LINK_WHATSAPP = "https://wa.me/+5491137979911"
const Welcome = ({ onContinue, phone }) => {

  const { inputStyles, selectedFieldStyles, buttonStyles, divStyles } = styles.styles;



  return (
        <div style={divStyles.center}>
          <CheckIcon style={finishStyle.checkIcon}/>  
        
          <Typography variant="h1" style={finishStyle.title}>
            Tu número finalizado en {phone} de Whatsapp fue asociado con éxito
          </Typography>
        
          <Typography variant="body2" style={finishStyle.subtitle}>
            Ya podés cerrar esta ventana del navegador.
          </Typography>
        
          <Button variant="contained" href={LINK_WHATSAPP} fullWidth>Abrir Whatsapp</Button>
        </div>
  );
};

const finishStyle = {
  checkIcon:{
    color:Colors.aquamarine,
    margin:20,  
    width:60,
    height:60
  },
  title:{
    color:Colors.aquamarine,
    marginBottom:40
  },
  subtitle:{
    marginBottom:40,
    textAlign:"center"
  }
}

export default Welcome