import { ACT_KEYBOARD } from "../actions/ActionTypes";

const initialState = {
  open:false
};

export default function(state = initialState, action) {
	if (action.type === ACT_KEYBOARD) {
		return Object.assign({}, state, action.props);
	}
	return state;
}
