import { ACT_LOADING_CHANGE, ACT_PHONE } from "../ActionTypes";
import AuthService from "../../provider/auth/AuthService";

// export function actRegister(user, successCallback, errorCallback) {
//   return (dispatch) => {
//     dispatch({
//       type: ACT_KEYBOARD,
//       props: { open: false },
//     });
//     dispatch({
//       type: ACT_LOADING_CHANGE,
//       props: { loading: true },
//     });
//     AuthService.createAccount(user)
//       .then((resp) => {
//         dispatch({
//           type: ACT_AUTH_CREATE_USER,
//           props: resp,
//         });
//         dispatch({
//           type: ACT_LOADING_CHANGE,
//           props: { loading: false },
//         });
//         successCallback();
//       })
//       .catch((err) => {
//         dispatch({
//           type: ACT_LOADING_CHANGE,
//           props: { loading: false },
//         });
//         errorCallback(err.message);
//       });
//   };
// }


export const actLogin =
  (user, successCallback, errorCallback) => (dispatch) => {
    // dispatch({
    //   type: ACT_KEYBOARD,
    //   props: {open: false}
    // });
    dispatch({
      type: ACT_LOADING_CHANGE,
      props: { loading: true },
    });
    AuthService.login(user)
      .then((res) => {
        console.log("LOGIN RESPONSE",res)
        dispatch({
          type: ACT_LOADING_CHANGE,
          props: { loading: false },
        });
        successCallback();
      })
      .catch((err) => {
        console.log("ERROR", err)
        dispatch({
          type: ACT_LOADING_CHANGE,
          props: { loading: false },
        });
        errorCallback(err);
      });
  };

  export const actPhoneLastNumbers =
  (wToken, successCallback, errorCallback) => (dispatch) => {
    AuthService.phoneLastNumbers(wToken)
      .then((res) => {
        console.log("PHONE LAST NUMBERS RESPONSE",res)
        dispatch({
          type:ACT_PHONE,
          phone:res.phone
        })
        successCallback(res);
      })
      .catch((err) => {
        console.log("ERROR", err)
        errorCallback(err.message);
      });
  };