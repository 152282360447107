import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
// import { sessionReducer } from 'redux-react-session';
import LoadingReducer from "./LoadingReducer";
import KeyboardReducer from "./KeyboardReducer";
import AuthReducer from "./AuthReducer";

const rootReducer = combineReducers({
  form: formReducer,
  loadingReducer: LoadingReducer,
  keyboard: KeyboardReducer,
  auth:AuthReducer
});

export default rootReducer;
