import C from "./Colors";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth:100
    // width: "30%",
  }
}));

const styles = {
  inputStyles: {
    underlineStyle: {
      borderColor: C.mediumBlue,
      borderWidth: "0.2vw",
    },
    underlineFocusStyle: {
      borderColor: C.mediumBlue,
      borderWidth: "0.2vw",
    },
    InputLabelProps: {
      color: C.darkGrey,
      fontSize: "1.8vw",
      fontFamily: "Nunito",
    },
    inputStyle: { fontSize: "1.8vw", fontFamily: "Nunito", color: C.darkGrey },
    inputStyleAvailable: { textAlign: "left" },
    style: { textAlign: "left" },
    fullWidth: true,
    floatingLabelFixed: true,
  },
  inputStylesAvailable: {
    underlineStyle: {
      borderColor: C.mediumBlue,
      borderWidth: "0.2vw",
    },
    underlineFocusStyle: {
      borderColor: C.mediumBlue,
      borderWidth: "0.2vw",
    },
    floatingLabelStyle: {
      color: C.darkGrey,
      fontSize: "1.8vw",
      fontFamily: "Nunito",
    },
    inputStyle: { fontSize: "1.8vw", fontFamily: "Nunito", color: C.darkGrey },
    inputStyleAvailable: { height: "68pt", textAlign: "left", width: "50%" },
    style: { textAlign: "left", width: "50%" },
    fullWidth: true,
    floatingLabelFixed: true,
  },
  selectedFieldStyles: {
    style: {
      color: C.darkGrey,
      height: "60pt",
      textAlign: "left",
      fontSize: "1.5vw",
      paddingTop: 10,
      fontFamily: "Nunito",
    },
  },
  buttonStyles: {
    // width: 492,
    marginTop: "1%",
    marginBottom: "2.5%",
    // height: "60%",
    fontSize: 18,
    textTransform: "none",
    borderRadius: 8,
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.16)"
  },
  divStyles: {
    center: {
      display: "flex",
      flexDirection: "column",
      // width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flex:1
    },
  },
};

export default { useStyles, styles };
