let _env = "PROD";

class Settings {
  static get env() {
    return _env;
  }

  static set env(value) {
    _env = value;
  }

  static BSF_API_KEY() {
    switch (_env) {
      case "LC":
        return "QyYEf4kltBYy_uxAgwcoBA==";
      case "DEV":
        return "QyYEf4kltBYy_uxAgwcoBA==";
      case "ST":
        return "QyYEf4kltBYy_uxAgwcoBA==";
      case "PROD":
        return "QyYEf4kltBYy_uxAgwcoBA==";
      default:
        return "QyYEf4kltBYy_uxAgwcoBA==";
    }
  }

  static SERVER_CF_URL() {
    switch (_env) {
      case "LC":
        return "http://localhost:3002"; //"https://apim-dev2.bancodeserviciosfinancieros.com.ar/api/s1gateway/1.0"; 
      case "DEV":
        return "https://apim-dev2.bancodeserviciosfinancieros.com.ar/api/s1gateway/1.0";
      case "ST":
        return "https://apim-test2.bancodeserviciosfinancieros.com.ar/api/s1gateway/1.0";
      case "PROD":
        return "https://apim.bancodeserviciosfinancieros.com.ar/api/s1gateway/1.0";
      default:
        return "https://southamerica-east1-gcp-ar-whatsapp-dev.cloudfunctions.net/api";
    }
  }

  static PRISMA_PROJECT_ID() {
    switch (_env) {
      case "LC":
        return "0c798100-9b79-42f5-beb0-83853b7b3905";
      case "DEV":
        return "0c798100-9b79-42f5-beb0-83853b7b3905";
      case "ST":
        return "0c798100-9b79-42f5-beb0-83853b7b3905";
      case "PROD":
        return "0c798100-9b79-42f5-beb0-83853b7b3905";
      default:
        return "0c798100-9b79-42f5-beb0-83853b7b3905";
    }
  }
}

export default Settings;
