import React, { useState } from "react";
import { withRouter } from "react-router-dom";
//redux
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { actLogin } from "../../actions/auth/AuthAction";
import { required, typeInteger } from "../../common/Validations";
import TextFieldRF from "../../components/Textfield";
import SelectFieldRF from "../../components/SelectField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//Styles
import { iconResumenBlue, disponibleIcon, profileIcon, candadoIcon } from "../../assets/images";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styles from "../../theme/GlobalStyles";
import Colors from "../../theme/Colors";
import MainContainer from "../../components/Containers/MainContainer";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from '@material-ui/core/Paper';


//ROUTES
import { RESUME_ROUTE } from "../../config/RoutesConsants";

//Notify
import Util, { getScreenSize, MOBILE, DESKTOP, ERROR } from "../../common/Util";

import { formatNumberWith0, toNumber } from "../../common/Util";
const screenSize = getScreenSize()
const LINK_FORGOT_PASS = "https://www.bancodeserviciosfinancieros.com.ar/#recuperar"
const LINK_REGISTER = "https://www.bancodeserviciosfinancieros.com.ar/#registrarse"
const LINK_TERMS = "https://www.bancodeserviciosfinancieros.com.ar/tycapp"
const LINK_POLICY = "https://www.bancodeserviciosfinancieros.com.ar/privacidad"



const Login = ({ handleSubmit, history, phone, onBack, goToFinish }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [typePassword, setTypePassword] = useState("password");
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError]  = useState(false);
  const dispatch = useDispatch()
  const { inputStyles, selectedFieldStyles, buttonStyles, divStyles } = styles.styles;

  const handleShowPassword = () => {
    if (typePassword === "password") {
      setTypePassword("text")
    } else {
      setTypePassword("password")
    }
  };

  const onLoginSuccess = ()=>{
    goToFinish()
  }

  const onLoginError = (e)=>{
    if(e.status==409){
      Util.notyError("Tipo o número de documento, usuario o contraseña incorrectos."); 
    }else{
      Util.notyError("Tuvimos problemas para loguearte."); 
    }
  }

  const onLogin = (values) => {
    if(terms){
      const wToken = history.location.search.split("?")[1]
      const data = {...values, wToken:wToken}
      dispatch(actLogin(data, onLoginSuccess,onLoginError));
    }else{
      Util.notyError("Debe aceptar los términos y condiciones."); 
    }
  };

  const onChangeTerms = (event)=>{
    setTerms(event.target.checked)
    setTermsError(false)
  }


  return (
    
      <div>
        <Typography variant="h1" style={screenSize==MOBILE?loginStyle.title:null} className="secondaryTitle">
          Ingresá tus datos para asociar tu número finalizado en {phone}
        </Typography>
      
      {/* <div className="inputContainer flex-row" style={{ marginTop: "2%" }}> */}
      <div style={{display:"flex"}}>
        <Field
          component={SelectFieldRF}
          name="idTipoDocumento"
          labelId="Tipo"
          validate={[required]}
          // {...inputStyles}
          // style={Object.assign(selectedFieldStyles.style, {
          //   minWidth:100
          // })}
          className="fieldDNI"
        >
          <MenuItem value={2}>DNI</MenuItem>
          <MenuItem value={1}>LC</MenuItem>
          <MenuItem value={3}>LE</MenuItem>
        </Field>
        {/* <div style={{ width: "27pt" }} /> */}
        <Field
          component={TextFieldRF}
          name="documento"
          type="text"
          label="Número"
          validate={[required, typeInteger]}
          // {...inputStyles}
          style={{marginLeft:20}}
          fullWidth
          format={(value) => formatNumberWith0(value)}
          parse={(value) => toNumber(value)}
        />
        </div>
      {/* </div>
      <div className="inputContainer"> */}
        <Field
          component={TextFieldRF}
          name="usuario"
          type="text"
          label="Usuario"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={profileIcon.default} />
              </InputAdornment>
            ),
          }}
          validate={[required]}
          fullWidth
          // {...inputStyles}
        />
        <Field
          component={TextFieldRF}
          name="password"
          type={typePassword}
          label="Contraseña"
          validate={[required]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={candadoIcon.default} />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {typePassword === "password" ? <div onClick={() => { handleShowPassword(); }}>
                  <InputAdornment>
                    <VisibilityIcon />
                  </InputAdornment>
                </div> :
                  <div onClick={() => { handleShowPassword() }}>
                    <InputAdornment>
                      <VisibilityOffIcon />
                    </InputAdornment>

                  </div>
                }
              </>
            ),
          }}
          fullWidth
          // {...inputStyles}
        />
      {/* </div> */}
      <Button href={LINK_FORGOT_PASS} target="_blank" style={{display:"flex", margin:"0 auto"}}>
        Olvidé mi usuario o contraseña
      </Button>
      <Typography variant="body2" style={loginStyle.registerText}>
        ¿No tenés usuario? <Button href={LINK_FORGOT_PASS} target="_blank" style={loginStyle.registerButton}>Registrate</Button>
      </Typography>
      <FormControlLabel
        control={<Checkbox color="primary" checked={terms} onChange={onChangeTerms}/>} 
        label={ <Typography variant="h5">Al aceptar los <Button href={LINK_TERMS} target="_blank"style={{fontSize:"inherit"}}>Términos y Condiciones</Button> y la <Button href={LINK_POLICY} target="_blank"style={{fontSize:"inherit"}}>Política de Privacidad</Button> estarás asociando tu cuenta de BSF con tu número de teléfono finalizado en {phone}</Typography>}
      />
      
      <div style={divStyles.center}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onLogin)}
          style={{...buttonStyles}}
          className="buttonAsociar"
        >
          Asociar Whatsapp
        </Button>
      </div>
    </div>
  );
};

const loginStyle = {
  backButton:{
    backgroundColor:"white",
    color:Colors.mediumBlue
  },
  registerText:{
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    marginTop:20
  },
  registerButton:{
    paddingLeft:10
  },
  title:{
    marginTop:50,
    marginBottom:10
  }
}

function mapStateToProps(store) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actLogin }, dispatch);
}

export default reduxForm({
  form: "login",
  initialValues: {idTipoDocumento:2}
})(withRouter(Login));
