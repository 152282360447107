import TextField from "@material-ui/core/TextField";

const TextFieldRF = ({ input, label, meta: { touched, error }, ...custom }) => (
  <TextField
    hintText={label}
    label={label}
    error={touched && error ? true : false}
    helperText={touched && error ? error : ""}
    {...input}
    {...custom}
    // inputProps={{
    //   autocomplete: custom.type=== "text" ? "new-password" :"off" ,
    // }}
    autoComplete="off"
    format="MM/dd/yyyy"
    InputLabelProps={{ shrink: true }}
  />
);

export default TextFieldRF;
