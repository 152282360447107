"use strict";

module.exports = {
  primary_color: "#6fbee4",
  darkText: "#032250",
  lightText: "#7F91A7",
  aquamarine:"rgb(11,217,178)",
  black_08: "#00000014",
  black_10: "#0000001b",
  black_12: "#0000001e",
  black_14: "#00000024",
  black_20: "#00000033",
  black_25: "#00000040",
  black_28: "#00000048",
  black_29: "#0000004a",
  black_38: "#00000061",
  black_54: "#0000008a",
  black_82: "#000000d1",
  black_87: "#000000dd",
  white_10: "#ffffff1a",
  white_30: "#ffffff4d",
  white_40: "#ffffff67",
  white_50: "#ffffff80",
  white_60: "#ffffff9a",
  white_70: "#ffffffb3",
  white_80: "#ffffffcd",
  white_90: "#ffffffe6",
  white_99: "#fffffffc",
  whiteFive: "#D9D9D9",
  warmGrey: "#808080",
  whiteTwo: "#E5E5E5",
  whiteThree: "#F8F8F8",
  blackTwo: "#1A1A1A",
  blackThree: "#000000",
  blueF: "#007894",
  blueButton: "rgb(59, 121, 150)",
  brownGrey: "#8E8E93",
  blueGrey: "rgb(142, 142, 147)",
  borderSeparator: "rgb(202,202,202)",
  brightCyan: "rgb(90, 196, 236)",
  cellBorder: "#EEEEEE",
  cerulean: "rgb(0,145,212)",
  coolBlue: "rgb(59,141,199)",
  coolBlueTwo: "rgb(60, 141, 199)",
  greenOne: "#009829",
  greyishBrown_87: "rgba(84, 84, 84, 0.87)",
  grayList: "rgb(162,162,162)",
  greyishBrown: "#4f4f4f",
  grey124: "rgb(124,124,124)",
  grey152: "rgb(152,152,152)",
  invalid: "rgb(254, 4, 0)",
  jadeGreen: "rgb(39, 174, 96)",
  lightGray: "rgb(224, 224, 224)",
  lightBlue: "rgb(111, 190, 228)",
  lightBlueTwo: "rgb(133, 194, 230)",
  lightBlueThree: "rgb(111, 194, 232)",
  lightishRed: "rgb(242, 61, 82)",
  lightSkyBlue: "rgb(205, 232, 247)",
  lightMustard: "rgb(244, 208, 98)",
  mediumBlue: "rgb(46, 117, 183)",
  gradientBlue: "rgb(0, 117, 191)",
  ocean: "#007894",
  pinkishGrey: "#C1C1C1",
  peacockBlue: "#0075be",
  rustyOrange: "#fcaf2a",
  veryLightPink: "rgb(240,240,240)",
  coral: "rgb(235, 87, 87)",
  darkGrey: "#7C7C7C",
  tealPositive: "#00987C",
  softGrey: "rgb(235, 235, 235)",
  placeHolder: "rgb(229, 229, 229)",
  greyVersion: "rgb(186, 186, 186)",
  greenOk: "rgb(0, 152, 124)",
  red030: "rgba(235, 87, 87, 0.3)",
  green030: "rgba(111, 207, 151, 0.3)",
  footerTabGray: "#9E9E9E",
};
