import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Body from "./components/Body/Body";
// import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
// import getMuiTheme from "material-ui/styles/getMuiTheme";
import Theme from "./theme/Theme"
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useIdleTimer } from "react-idle-timer";
import { HOME_ROUTE } from "./config/RoutesConsants";
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
// const muiTheme = getMuiTheme({});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    outline: "none",
  },
}));

function App({ loading, history }) {
  const handleOnIdle = (event) => {
    history.push(HOME_ROUTE);
  };

  const handleOnActive = (event) => {
  };

  const handleOnAction = (event) => {
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const classes = useStyles();

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {/* <MuiThemeProvider muiTheme={muiTheme}> */}
        <Theme>
          <Modal className={classes.modal} open={loading} closeAfterTransition>
            <Fade in={loading}>
              <div className={classes.root}>
                <CircularProgress />
              </div>
            </Fade>
          </Modal>
          <Body />
        </Theme>
        {/* </MuiThemeProvider> */}
      </MuiPickersUtilsProvider>
    </>
  );
}

function mapStateToProps(store) {
  return {
    loading: store.loadingReducer.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
