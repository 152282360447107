import { Settings } from "../base";
import APIConnector from "../lib/APIConnector";

const connector = new APIConnector({ timeout: 10000 });

export default class AuthConfig {
  static get Connector() {
    return connector;
  }
  
  static get base_cf() {
    return Settings.SERVER_CF_URL();
  }

  static get preRegister() {
    return `${AuthConfig.base_cf}/auth/register`;
  }

  static get login() {
    return `${AuthConfig.base_cf}/auth/login`;
  }

  static get phoneLastNumbers() {
    return `${AuthConfig.base_cf}/auth/phone`;
  }

  
}
