export const background = require("./backgroundicons.svg");
export const logo = require("./carrefourTarjetaLogo.svg");
export const iconDisponible = require("./iconDisponibleWhite.svg");
export const iconDisponibleBlue = require("./iconDisponibleBlue.svg");
export const iconRegitro = require("./iconRegistroWhite.svg");
export const iconResumen = require("./iconResumenesWhite.svg");
export const iconResumenBlue = require("./iconResumenesBlue.svg");
export const iconResumenLightBlue = require("./iconResumenesLightBlue.svg");
export const iconContacto = require("./contacto-icon.svg");
export const next = require("./next.svg");
export const previous = require("./previous.svg");
export const insigniaAndroid = require("./insigniaAndroid.png");
export const insigniaIos = require("./insigniaIos.png");
export const qr = require("./QR.png");
export const home = require("./home.svg");
export const idIcon = require("./id-icon.svg");
export const disponibleIcon = require("./icon-disponible.svg");
export const tarjetaIcon = require("./tarjetaIconDisponible.svg");
export const iconPagoMinimo = require("./iconPagoMinimo.svg");
export const pinTimeLine = require("./pinTimeLine.svg");
export const iconTotal = require("./iconTotal.svg");
export const mailIcon = require("./mailIcon.svg");
export const closeIcon = require("./close.svg");
export const mailIconModal = require("./mailIconModal.svg");
export const pencilIcon = require("./pencilIcon.svg");
export const checkIcon = require("./checkIcon.svg");
export const iconRegistroBlue = require("./iconRegistroBlue.png");
export const successIcon = require("./okIcon.svg");
export const candadoIcon = require("./lockIcon.svg");
export const profileIcon = require("./profileLoginIcon.svg");
export const calendarioIcon = require("./icon-down.svg");
export const deleteKeyboardIcon = require("./deleteKeyboardIcon.svg");
export const backgroundLight = require("./backgroundLight.svg");
export const Logos_carrefourTarjetaLogoWhite = require("./Logos_carrefourTarjetaLogoWhite.svg");
export const bgCircleLeft = require("./bgCircleLeft.svg");
export const bgCircleRight = require("./bgCircleRight.svg");
export const bgTopLogo = require("./bgTopLogo.svg");
export const Icons_WhatsappIcon = require("./Icons_WhatsappIcon.svg")



