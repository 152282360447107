import { ACT_LOADING_CHANGE } from "../actions/ActionTypes";

const initialState = {
  loading: false,
};

export default function (state = initialState, action) {
  if (action.type === ACT_LOADING_CHANGE) {
    return Object.assign({}, state, action.props);
  }
  return state;
}
