import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
//redux
import { useDispatch } from "react-redux";
import { actPhoneLastNumbers } from "../../actions/auth/AuthAction"

import { required, typeInteger } from "../../common/Validations";
import TextFieldRF from "../../components/Textfield";
import SelectFieldRF from "../../components/SelectField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
//Styles
import "./Home.css";


import { backgroundLight } from "../../assets/images";
import bgCircleLeft from "../../assets/images/bgCircleLeft.svg"
import bgCircleRight from "../../assets/images/bgCircleRight.svg"
import bgTopLogo from "../../assets/images/bgTopLogo.svg"
import Logos_carrefourTarjetaLogoWhite from "../../assets/images/Logos_carrefourTarjetaLogoWhite.svg"
import Icons_WhatsappIcon from "../../assets/images/Icons_WhatsappIcon.svg"
import Icons_lockIcon from "../../assets/images/Icons_lockIcon.svg"
import Terminos from "../../assets/images/Terminos.svg"

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styles from "../../theme/GlobalStyles";
import Colors from "../../theme/Colors";
import MainContainer from "../../components/Containers/MainContainer";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Container from "@material-ui/core/Container"
import BackIcon from '@material-ui/icons/ChevronLeft'
import { withStyles } from '@material-ui/core/styles';

//ROUTES
import { RESUME_ROUTE } from "../../config/RoutesConsants";

//Notify
import Util, { getScreenSize, ERROR, DESKTOP, MOBILE } from "../../common/Util";

import { formatNumberWith0, toNumber } from "../../common/Util";

import Welcome from "./Welcome"
import Login from "../Login/Login"
import Finish from "./Finish"
const screenSize = getScreenSize()
const Home = ({ handleSubmit, history }) => {
  const [screen, setScreen] = useState(0);
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch()
  const wToken = history.location.search.split("?")[1]

  useEffect(()=>{
    dispatch(actPhoneLastNumbers(wToken,onPhoneSuccess, onPhoneError))
  }, [wToken])

  const onPhoneSuccess = (res)=>{
    console.log("PHONE SUCESS", res)
    setPhone(res.phone)
    if(res.registered){
      setScreen(2)
    }
  }

  const onPhoneError = (e)=>{
    //Que hago si hay error al buscar el teléfono ?
  }
  // const { inputStyles, selectedFieldStyles, buttonStyles, divStyles } = styles.styles;
  const renderContent = ()=>{
    console.log("SCREEN", screen)
    console.log("PHONE", phone)
    let content = null
    switch (screen) {
      case 0:
        content = <Welcome onContinue={()=>setScreen(1)}/>; break;
      case 1: 
        content = <Login phone={phone} goToFinish={()=>setScreen(2)}/>; break;
      case 2:
        content = <Finish phone={phone}/>; break;
      default:
        break;
    }

    switch (screenSize) {
      case MOBILE: 
        return content   
      case DESKTOP: 
        return <Paper elevation={3} style={homeStyle.paper} className="MainPaper">{content}</Paper>
      default:
        break;
    }
  }


  return (
    <Container style={homeStyle.container}>
      {screen==1&&
      <div style={{position: "absolute", top: 10, left: 20}}>
        <BackButton startIcon={<BackIcon/>} onClick={()=>setScreen(0)}>Volver</BackButton>
      </div>
      }
      <img src={bgCircleLeft} className="bgCircleLeft"/>
      <img src={bgCircleRight} className="bgCircleRight"/>
      <img src={bgTopLogo} className="bgCircleTop"/>
      <img src={Logos_carrefourTarjetaLogoWhite} className="Logos_carrefourTarjetaLogoWhite"/>
      {renderContent()}
      <Typography variant="h6">Banco de Servicios Financieros S.A</Typography>
    </Container>
  );
};

const BackButton = withStyles({
  root:{
    background:"white",
    boxShadow: "0px 1.1px 3.2px 0px rgba(0,0,0,0.25)",
    borderRadius:16.8,
    margin:10,
    padding:8
  },
  label:{
    color:Colors.mediumBlue,
    fontSize:"1rem",
    textAlign:"left",
    paddingRight:6
  }
})(Button)

const homeStyle={
  container:{
    background:`url(${backgroundLight})`, 
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    maxWidth:"100%",
    height: "100%"
  },
  // footer:{
  //   color:Colors.grey152,
  //   fontSize: 13,
  //   textAlign:"center",
  //   marginTop: 30,
  //   display: "flex",
  //   alignItems:"center",
  //   justifyContent: "center"
  // },
  paper:{
    padding: 28,
    paddingLeft:"10%",
    paddingRight:"10%",
    marginTop:20,
    borderRadius:40
  },
}



export default withRouter(Home);
