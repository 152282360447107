import React, { useState } from "react";
import { withRouter } from "react-router-dom";
//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { required, typeInteger } from "../../common/Validations";
import TextFieldRF from "../../components/Textfield";
import SelectFieldRF from "../../components/SelectField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
//Styles
import "./Welcome.css";


import { backgroundLight } from "../../assets/images";
import bgCircleLeft from "../../assets/images/bgCircleLeft.svg"
import bgCircleRight from "../../assets/images/bgCircleRight.svg"
import bgTopLogo from "../../assets/images/bgTopLogo.svg"
import Logos_carrefourTarjetaLogoWhite from "../../assets/images/Logos_carrefourTarjetaLogoWhite.svg"
import Icons_WhatsappIcon from "../../assets/images/Icons_WhatsappIcon.svg"
import Icons_lockIcon from "../../assets/images/Icons_lockIcon.svg"
import Terminos from "../../assets/images/Terminos.svg"

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styles from "../../theme/GlobalStyles";
import Colors from "../../theme/Colors"
 
import MainContainer from "../../components/Containers/MainContainer";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Container from "@material-ui/core/Container"

//ROUTES
import { RESUME_ROUTE } from "../../config/RoutesConsants";

//Notify
import Util, { ERROR } from "../../common/Util";

import { formatNumberWith0, toNumber } from "../../common/Util";


const Welcome = ({ onContinue }) => {

  const { inputStyles, selectedFieldStyles, buttonStyles, divStyles } = styles.styles;



  return (
        <div style={divStyles.center}>
          <img src={Icons_WhatsappIcon} className="Icons_WhatsappIcon"/>  
          <Typography variant="h1">
            Hola, vamos a habilitar tu Whatsapp.
          </Typography>
          <Typography variant="h2">
            Para configurarlo, te pedimos:
          </Typography>
        
          <Stepper orientation="vertical" connector={<StepConnector/>}>
            <Step>
              <StepLabel completed 
                          icon={
                            <div className="divCircle">
                              <img src={Icons_lockIcon} className="Icons_lockIcon"/>
                            </div>}>
                  <Typography variant="body2" className="firstScreenLabels">Iniciar sesión en la siguiente pantalla</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel completed 
                          icon={<img src={Terminos}/>}>
                <Typography variant="body2">Aceptar los términos y condiciones</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel completed
                        icon={
                          <div className="divCircle">
                            <img src={Icons_WhatsappIcon} width={31}/>
                          </div>}> 
                <Typography variant="body2">Abrí Whatsapp y consultá tus datos</Typography>
              </StepLabel>
            </Step>
          </Stepper>
          <Button color="primary" variant="contained" className="button"  onClick={onContinue} style={{fontSize:18}}>Continuar</Button>
        </div>
      
  );
};


export default Welcome