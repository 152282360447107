import React from "react";
import {useSelector} from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";

//Constants
import {
  HOME_ROUTE
} from "./RoutesConsants";

//Screens

import Login from "../screens/Login/Login";
import Home from "../screens/Home/Home"
const Routes = () => {
  return (
    <Switch>
     <Route path={HOME_ROUTE} exact component={Home} />
     {/* <Route path={HOME_ROUTE} exact component={Login} /> */}
    </Switch>
  );
};

export default Routes;
