import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Styles from "../../theme/GlobalStyles";
import {idIcon} from "../../assets/images";

const { useStyles } = Styles;


const SelectFieldRF = ({
  input,
  labelId,
  meta: { touched, error },
  children,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.FormControl}>
      <img src={idIcon.default} alt="idIcon" style={{width:"25px", position:"absolute", top:"28px"}}/>
      <InputLabel id={labelId}>Tipo</InputLabel>
      <Select
        labelId={labelId}
        errorText={touched && error}
        {...input}
        children={children}
      />
    </FormControl>
  );
};

export default SelectFieldRF;
