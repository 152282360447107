import React from 'react'
import Routes from "../../config/Routes";

const Body = () => {
    return(
        <>
            <Routes/>
        </>
    )
};

export default Body;